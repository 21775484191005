import React from 'react'
import {  NavLink } from 'react-router-dom'
import style from './style/sidebar.css'

export default function Sidebar({topics}) {
 
  return (
    <div className='index'>
        {topics.map(topic => (
          <NavLink key={topic} style={({ isActive }) => ({
            color: isActive ? "white" : "black",
            backgroundColor: isActive ? "black" : "rgb(215, 179, 246)",
            fontWeight: isActive ? "bold" : "",
          })} to={topic.path}>{topic.title}</NavLink>
        ))}
    </div>
  )
}
