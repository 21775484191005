import React from "react";
import image from "../image/smily.png";
export default function HtmlLinks() {
  return (
    <div className="bContainer container">
      <h1>Links in HTML</h1>
      <p>
        Links lagbhag sabhi web pages mein milte hain. Links users ko ek se
        doosre page tak le jaane help karte hain.
      </p>
      <p>HTML links hyperlinks hote hain.</p>
      <p>
        Aap ek link par click karke kisi aur document par jump kar sakte hain.
      </p>
      <p>
        Jab aap mouse ko ek link ke upar le jaate hain, mouse ka arrow ek
        pointer mein badal jaata hai.
      </p>
      <h2>HTML Links - Syntax</h2>
      <p>
        {" "}
        the HTML {`<a>`} tag defines a hyperlink. It has the following syntax:
      </p>
      <p>
        <a href="https://hinglishweb.physitask.com">link text</a>
      </p>
      <p>
        {`<a>`} element ka most important attribute href hota hai, jo link ke
        destination ko show karta hai.
      </p>
      <p>Link text wo part hai jo user ko show hoga.</p>
      <p>
        Link text par click karne pe, user specified URL address par chala
        jayega.
      </p>
      <h3>Example</h3>
      <p>
        <a href="https://hinglishweb.physitask.com">Hinglish Web!</a>
      </p>
      <p>By default, sabhi browsers mein links is prakar dikhege:</p>
      <p>- Ek unvisited link underline aur blue hoga.</p>
      <p>- Ek visited link underline aur purple hoga.</p>
      <p>- Ek active link underline aur red hoga.</p>
      <h2>HTML Links - The target Attribute</h2>
      <p>
        By default, linked page current browser window mein dikhaya jayega. Isko
        change karne ke liye, aapko link ke liye ek aur target specify karna
        hoga.
      </p>
      <p>
        Target attribute ye batata hai ki linked document ko kahan open karna
        hai.
      </p>
      <p>Target attribute mein inme se koi bhi ek value ho sakti hai:</p>
      <p>
        _self - Default. Document ko wahi window/tab mein open karta hai jahan
        se click kiya gaya tha.
      </p>
      <p>_blank - Document ko ek naye window ya tab mein open karta hai.</p>
      <p>_parent - Document ko parent frame mein open karta hai.</p>
      <p>_top - Document ko window ke puri body mein open karta hai.</p>
      <h3>Example</h3>
      <p>
        <a href="https://hinglishweb.physitask.com" target="_blank">
          Hinglish Web
        </a>
        <br />
        Is example mein, link {`<a>`} element ke href attribute mein diye gaye
        URL address par jaayega, lekin target attribute ke value `_blank` hone
        se ye naya window ya tab mein khulega.
      </p>
      <h2>Absolute URLs vs. Relative URLs</h2>
      <p>
        Dono upar ke example mein href attribute mein ek absolute URL (poora web
        address) ka istemal kiya gaya hai.
      </p>
      <p>
        Local link (same website ke andar kisi page ka link) ke liye ek relative
        URL ka istemal hota hai (jisme "https://www" ka hissa nahi hota):
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
        <a href="/about">Go to About Page</a>
        `}{" "}
      </pre>
      <h3>Output</h3>
      <div className="bgwhite">
        <a href="/about">Go to About Page</a>
      </div>
      <p>
        Is example mein, link {`<a>`} element ke href attribute mein `/about`
        diya gaya hai, jo ki ek relative URL hai aur isse website ke andar About
        page par jaayega.
      </p>
      <h2>HTML Links - Use an Image as a Link</h2>
      <p>
        To use an image as a link, just put the {`<img>`} tag inside the {`<a>`}{" "}
        tag:
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
        <a href="default.asp">
        <img src="smiley.gif" alt="HTML tutorial" style="width:42px;height:42px;">
        </a>
        `}
      </pre>
      <h3>Output</h3>
      <div className="bgWhite">
        <h4>Click On Image Below :</h4>
        <a href="/">
          <img
            src={image}
            alt="HTML tutorial"
            style={{ width: "70px", height: "70px" }}
          />
        </a>
      </div>
      <h2>Link to an Email Address</h2>
      <p>
        `mailto:` ka istemal karein href attribute ke andar ek link banane ke
        liye jisse user apne email program ko khol sake (taki unhe ek naya email
        bhejne ka option mile).
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
        <a href="mailto:example@example.com">Send Email</a>

        `}
      </pre>
      <p>Is example mein, link {`<a>`} element ke href attribute mein `mailto:example@example.com` diya gaya hai, jisse user apne email program ke through ek naya email send karne ke liye redirect hoga.
</p>
<h2>Button as a Link
</h2>
<p>HTML button ko ek link ke roop mein istemal karne ke liye, aapko kuch JavaScript code add karna hoga.
</p>
<p>JavaScript aapko kuch events jaise ki button click par kya hoga, aise specify karne mein help karta hai.
</p>
<pre className="bgExample">
    {`
    <button onclick="document.location='/web-development'">Web Development</button>
    `}
</pre>

    </div>
  );
}
