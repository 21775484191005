import React from "react";

export default function BlockAndInlineElements() {
  return (
    <div className="bContainer container">
      <h1>HTML Block and Inline Elements</h1>
      <p>
        Har HTML element ka ek default display value hota hai, jo us element ke
        type par depend karta hai.
      </p>
      <p>Do sabse common display values hote hain block aur inline.</p>
      <h2>Block-level Elements</h2>
      <p>
        Block-level element hamesha ek naye line se shuru hota hai, aur browsers
        apne aap element ke pehle aur baad mein kuch space (margin) add karte
        hain.
      </p>
      <p>
        Block-level element hamesha puri width le leta hai jo available hai
        (left aur right tak stretch karta hai jitna ho sake).
      </p>
      <p>
        Do aam taur par istemal hone wale block elements hain: {`<p> `}aur{" "}
        {`<div>`}.
      </p>
      <p>
        The {`<p>`} element ek paragraph ko define karta hai HTML document mein.
      </p>
      <p>
        The {`<div>`} element ek division ya section ko define karta hai HTML
        document mein.
      </p>
      <p>The {`<p> `}element ek block-level element hai.</p>
      <p>The {`<div> `}element bhi ek block-level element hai.</p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
        <div>Ye 'div' ek block-level element hai</div>
        <p>Ye 'p' bhi ek block-level element hai</p>
        `}
      </pre>
      <h3>Output</h3>
      <div className="bgWhite">
        <div>Ye 'div' ek block-level element hai</div>
        <p>Ye 'p' bhi ek block-level element hai</p>
      </div>
      <h3>Here are the block-level elements in HTML:</h3>
      <p className="clrRed">
        {`<address> `}
        {`<article> `}
        {`<aside> `}
        {`<blockquote> `}
        {`<canvas> `}
        {`<dd> `}
        {`<div> `}
        {`<dl> `}
        {`<dt> `}
        {`<fieldset> `}
        {`<figcaption> `}
        {`<figure> `}
        {`<footer> `}
        {`<form> `}
        {`<h1> - <h6> `}

        {`<header> `}
        {`<hr> `}
        {`<li> `}
        {`<main> `}
        {`<nav> `}
        {`<noscript> `}
        {`<ol> `}
        {`<p> `}
        {`<pre> `}
        {`<section> `}
        {`<table> `}
        {`<tfoot> `}
        {`<ul> `}
        {`<video> `}
      </p>
      <h2>Inline Elements</h2>
      <p>Ek inline element naye line par shuru nahi hota.</p>
      <p>
        Ek inline element sirf utna width leta hai jitna ki zaroorat hoti hai.
      </p>
      <h3>Example</h3>
      <p className="clrRed">
        {`<address> `}
        {`<a> `}
        {`<abbr> `}
        {`<abbr> `}
        {`<acronym> `}
        {`<b> `}
        {`<bdo> `}
        {`<big> `}
        {`<br> `}
        {`<button>`}
        {`<cite> `}
        {`<code> `}
        {`<dfn> `}
        {`<em> `}
        {`<i> `}

        {`<img>`}
        {`<input> `}
        {`<kbd>`}
        {`<label> `}
        {`<map> `}
        {`<object> `}
        {`<output> `}
        {`<q> `}
        {`<samp> `}
        {`<script> `}
        {`<select> `}
        {`<small> `}
        {`<span> `}
        {`<strong> `}
        {`<sub> `}
        {`<sup> `}
        {`<var> `}
        {`<tt> `}
        {`<time> `}
        {`<textarea> `}
      </p>
      <h2>The{` <div>`} Element</h2>
      <p>
        The {`<div>`} element ko often doosre HTML elements ke liye ek container
        ke roop mein istemal kiya jata hai.
      </p>
      <p>
        The {`<div>`} element ko koi required attributes nahi hote, lekin style,
        class aur id common hote hain.
      </p>
      <p>
        Jab CSS ke saath istemal kiya jata hai, tab {`<div>`} element ko content
        ke blocks ko style dene ke liye istemal kiya ja sakta hai:
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
        <div style="background-color:black;color:white;padding:20px;">
        <h2>India</h2>
        <p>
        Hinglish mein seekho programming.
        </p>
        </div>
      `}
      </pre>
      <h2>The {`<span>`} Element</h2>
      <p>
        The {`<span>`} element ek inline container hai jo kisi text ka hissa ya
        document ka hissa markup karne ke liye istemal hota hai.
      </p>
      <p>
        The {`<span>`} element ko koi required attributes nahi hote, lekin
        style, class, aur id common hote hain.
      </p>
      <p>
        Jab CSS ke saath istemal kiya jata hai, tab {`<span>`} element ko text
        ke kuch hisson ko style dene ke liye istemal kiya ja sakta hai:
      </p>
    </div>
  );
}
