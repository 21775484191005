import React from "react";

export default function CommentsHtml() {
  return (
    <div className="bContainer container">
      <h1>Comments in HTML</h1>
      <p>
        HTML comments browser mein user ko show nahi hote, lekin ye aapke HTML
        source code ko document karne mein madad kar sakte hain.
      </p>
      <h2>HTML Comment Tag</h2>
      <p>
        Aap apne HTML source code mein comments add kar sakte hain neeche diye
        gaye syntax ka istemal karke:
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
        <!-- Ye ek comment hai -->

        <p>Ye ek paragraph hai.</p>
        
        <!-- Yaad rahe, yahan aur jankari add karein -->
        <!-- ye bhi ek comment hai -->
        
        `}
      </pre>
      <h2>Hide Content with Comments</h2>
      <p>
        Comments ka istemal content hide karne ke liye kiya ja sakta hai. <br />
        Ye helpful ho sakta hai agar aap temporarily kuch content hide kar rahe
        hain:
      </p>
      <h3>
        Example
      </h3>
      <pre className="bgExample">
        {`
        <p>i am a paragraph.</p>


        <!-- <p>i am another paragraph </p> -->
        
        
        <p>i am a paragraph too.</p>
        
        `}
      </pre>
    </div>
  );
}
