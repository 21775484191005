import React from "react";

export default function ListsHtml() {
  return (
    <div className="bContainer container">
      <h1>Lists In HTML</h1>
      <p>
        HTML lists web developers ko ek set ke related items ko lists mein group
        karne mein help karte hain.
      </p>
      <h2>An unordered list</h2>
      <p>
        Ek unordered list {`<ul>`} tag ke saath shuru hoti hai. Har list item{" "}
        {`<li>`} tag ke saath shuru hota hai.
      </p>
      <p>
        List items ko default taur par bullets (chhote kala gol) se mark kiya
        jayega:
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
         <ul>
         <li>Baari</li>
         <li>Jeena Jeena</li>
         <li>Dil</li>
         <li>Mashooqana</li>
         </ul>
        `}
      </pre>
      <h3>Output</h3>
      <div className="bgWhite">
        <ul>
          <li>Baari</li>
          <li>Jeena Jeena</li>
          <li>Dil</li>
          <li>Mashooqana</li>
        </ul>
      </div>
      <h2>Ordered List</h2>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
        <ol>
        <li>Animal</li>
        <li>Kantaara</li>
        <li>KGF</li>
        <li>War</li>
        </ol>
      `}
      </pre>
      <h3>Output</h3>
      <div className="bgWhite">
        <ol>
          <li>Animal</li>
          <li>Kantaara</li>
          <li>KGF</li>
          <li>War</li>
        </ol>
      </div>
      <h2>HTML Description Lists</h2>
      <p>
        HTML mein description lists ko bhi support hai.
        <br />
        Ek description list terms ki ek list hai, jisme har term ka ek
        description hota hai.
        <br />
        {`<dl>`} tag description list ko define karta hai, {`<dt>`} tag term
        (name) ko define karta hai, aur {`<dd>`} tag har term ko describe karta
        hai:
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
       {`
         <dl>
         <dt>Baari</dt>
         <dd>Singer: Bilal Saaed</dd>
         <dt>Tera Zikr</dt>
         <dd>Singer: Darshan Raval</dd>
         <dt>Husn</dt>
         <dd>Singer: Anuv Jain</dd>
         </dl>
      `}
      </pre>
      <h3>Output</h3>
      <div className="bgWhite">
      <dl>
          <dt>Baari</dt>
          <dd>Singer: Bilal Saaed</dd>
          <dt>Tera Zikr</dt>
          <dd>Singer: Darshan Raval</dd>
          <dt>Husn</dt>
          <dd>Singer: Anuv Jain</dd>
        </dl>
      </div>
    </div>
  );
}
