import React from "react";

export default function DivHtml() {
  return (
    <div className="bContainer container">
      <h1> Div Element HTML</h1>
      <p>
        {`<div> `}element doosre HTML elements ke liye ek container ke jaise
        istemal hota hai.{" "}
      </p>
      <h2>The {`<div>`} Element</h2>
      <p>
        {`<div>`} element by default ek block element hota hai, yani ki yeh all
        available width ko leta hai, aur uske before aur after line breaks ke
        saath aata hai.
      </p>
      <p>A {`<div> `}element takes up all available width:</p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
        Lorem Ipsum <div>Main Hoon Ek div Element</div> dolor sit amet
        `}
      </pre>
      <h3>Output</h3>
      <div className="bgWhite">
        Lorem Ipsum <div>Main Hoon Ek div Element</div> dolor sit amet
      </div>
      <p>
        {`<div>`} element mein koi required attributes nahi hote, lekin style,
        class, aur id common attributes hain jo use hote hain.
      </p>
      <p>
        {`<div>`} element ko web page ke sections ko ek saath group karne ke
        liye often istemal kiya jata hai.
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
        <div>
        <h2>Animal</h2>
        <p>Song: Hua Main</p>
        <p>Lyrics: Manoj Muntashir </p>
        <p>Music: Jam8</p>
        </div>
      `}
      </pre>
      <h3>Output</h3>
      <div className="bgWhite">
        <div>
          <h2>Animal</h2>
          <p>Song: Hua Main</p>
          <p>Lyrics: Manoj Muntashir </p>
          <p>Music: Jam8</p>
        </div>
      </div>
      <h2>Center align a {`<div>`} element</h2>
      <p>
        Agar aapke paas ek{`<div>`} element hai jo 100% wide nahi hai, aur aap
        use center-align karna chahte hain, toh CSS margin property ko{" "}
        <b>auto</b> pe set karein.
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
        <style>
        div {
          width:300px;
          margin:auto;
        }
        </style>
        `}
      </pre>
      <h3>Output</h3>
      <div className="bgWhite">
        <div style={{ width: "300px", margin: "auto" }}>
          <h2>Animal</h2>
          <p>Song: Hua Main</p>
          <p>Lyrics: Manoj Muntashir </p>
          <p>Music: Jam8</p>
        </div>
      </div>
      <h2>Multiple {`<div>`} elements</h2>
      <p>Aap ek hi page par kai {`<div> `}containers rakh sakte hain.</p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
        <div>
        <h2>Movie - Animal</h2>
        <p>Song: Hua Main</p>
        <p>Lyrics: Manoj Muntashir </p>
        <p>Music: Jam8</p>
        </div>
        <div>
        <h2>Movie - RRR</h2>
        <p>Song: Ambar Se Toda</p>
        <p>Singer: Raag Patel</p>
        <p>Lyrics: Varun Grover </p>
        </div>
        <div>
        <h2>Movie - RRR</h2>
        <p>Song: Naatu Naatu</p>
        <p>Singer: Vishal Mishra</p>
        <p>Lyrics: Riya Mukharjee </p>
        </div>
        `}
      </pre>
      <h3>Output</h3>
      <div className="bgWhite">
        <div>
          <h2>Movie - Animal</h2>
          <p>Song: Hua Main</p>
          <p>Lyrics: Manoj Muntashir </p>
          <p>Music: Jam8</p>
        </div>
        <div>
          <h2>Movie - RRR</h2>
          <p>Song: Ambar Se Toda</p>
          <p>Singer: Raag Patel</p>
          <p>Lyrics: Varun Grover </p>
        </div>
        <div>
          <h2>Movie - RRR</h2>
          <p>Song: Naatu Naatu</p>
          <p>Singer: Vishal Mishra</p>
          <p>Lyrics: Riya Mukharjee </p>
        </div>
       </div>
      
        
    </div>
  );
}
