import React, { useState } from "react";

export default function JavascriptHtml() {
  const [text, setText] = useState("Hello India!");

  const changeText = () => {
    setText("Namaste User!");
  };
  return (
    <div className="bContainer container">
      <h1> JavaScript in HTML</h1>
      <p>JavaScript makes HTML pages more dynamic and interactive.</p>
      <p>
        In Hindi, "HTML {`<script> `}tag ko ek client-side script (JavaScript)
        ko specify karne ke liye istemal kiya jata hai.
      </p>
      <p>
        {`<script>`} element ya toh script statements ko contain karta hai, ya
        phir src attribute ke madhyam se ek external script file ko point karta
        hai.
      </p>
      <p>
        JavaScript ke aam istemal hain image manipulation, form validation, aur
        content ki dynamic changes.
      </p>
      <p>
        Ek HTML element ko chunne ke liye, JavaScript sabse adhik
        document.getElementById() method ka istemal karta hai.
      </p>
      <p>
        Is JavaScript example mein "Hello JavaScript!" ko ek HTML element ke
        andar id="demo" ke saath likhta hai:"
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
         <!DOCTYPE html>
         <html>
         <body>
         
         
         <h2 id="myHeader">Hello India!</h2>
         <button onclick="displayResult()">Change text</button>
         
         <script>
         function displayResult() {
           document.getElementById("myHeader").innerHTML = "Namaste User!";
         }
         </script>
         
         </body>
         </html>
        `}
      </pre>
      <h3>Output</h3>
      <div className="bgWhite">
        <h2>{text}</h2>
        <button onClick={changeText}>Change text</button>
      </div>
      <h3>Kuch aur examples ke javascript kya kya kar sakta hai:</h3>
      <pre className="bgExample">
        {`
        <!DOCTYPE html>
        <html>
        <body>
        
        
        <p id="tryBall">JavaScript can change the style of an HTML element.</p>
        
        <script>
        function myFunction() {
          document.getElementById("tryBall").style.fontSize = "25px"; 
        }
        function myFunction2() {
            document.getElementById("tryBall").style.color = "red";
        }
        function myFunction2() {
            document.getElementById("tryBall").style.backgroundColor = "yellow";        
        }

        </script>
        
        <button type="button" onclick="myFunction()">Click Me to change background color!</button>
        <br />

        <button type="button" onclick="myFunction2()">Click Me to change color!</button>
        <br />

        <button type="button" onclick="myFunction3()">Click Me to add border!</button>
        
        </body>
        </html>
        `}
      </pre>
      <h3>Output</h3>

      <div className="bgWhite">
        <p id="tryBall">JavaScript can change the style of an HTML element.</p>

        <button
          type="button"
          onClick={() => {
            document.getElementById("tryBall").style.backgroundColor = "red";
          }}
        >
          Click Me to change background color!
        </button>
        <br />
        <button
          type="button"
          onClick={() => {
            document.getElementById("tryBall").style.color = "white";
          }}
        >
          Click Me to change color!
        </button>
        <br />

        <button
          type="button"
          onClick={() => {
            document.getElementById("tryBall").style.border = "2px solid black";
          }}
        >
          Click Me to add border!
        </button>
      </div>
    </div>
  );
}
