import React from "react";

export default function FilePathHtml() {
  return (
    <div className="bContainer container">
      <h1> File Paths in HTML</h1>
      <p>
        Ek file path ek web site ke folder structure mein ek file ki place ko
        describe karta hai.
      </p>
      <p>
        File paths ka istemal tab hota hai jab external files se joda jata hai,
        jaise:
      </p>
      <p>- Web pages</p>
      <p>- Images</p>
      <p>- Style sheets</p>
      <p>- JavaScripts"</p>
    </div>
  );
}
