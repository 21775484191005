import React from "react";

export default function ImagesHtml() {
  return (
    <div className="bContainer container">
      <h1> Images in HTML</h1>
      <p>
        Images ek web page ke design aur appearance ko sudharne mein help karti
        hain.
      </p>
      <h2>HTML Images Syntax</h2>
      <p>
        HTML {`<img>`} tag ka istemal ek image ko web page mein embed karne ke
        liye hota hai.
      </p>
      <p>
        Technically, images ko web page mein insert nahi kiya jata hai; images
        ko web pages se link kiya jata hai. {`<img>`} tag ek referenced image ke
        liye ek holding space banata hai.
      </p>
      <p>
        {`<img>`} tag khali hota hai, isme sirf attributes hote hain, aur iska
        koi closing tag nahi hota.
      </p>
      <p>{`<img>`} tag ke do required attributes hote hain:</p>
      <p>`src` - Image ka path specify karta hai.</p>
      <p>`alt` - Image ke liye alternate text specify karta hai.</p>
      <h3>Syntax</h3>
      <pre className="bgExample">
        {`<img src="url" alt="alternatetext">
`}
      </pre>
      <h3>The src Attribute</h3>
      <p>Required `src` attribute image ke path (URL) ko specify karta hai.</p>
      <p>
        Note: Jab ek web page load hota hai, tab wahi browser, us samay, image
        ko web server se lekar usse page mein insert karta hai. Isliye, dhyan
        rakhein ki image real mein web page ke sath same spot par rahe, nahi to
        aapke visitors ko ek broken link icon dikhega. Agar browser image ko
        nahi access kar paata, toh broken link icon aur `alt` text dikhai dete
        hain.
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
    <img src="img_chania.jpg" alt="Flowers in Chania">
    `}
      </pre>
      <h3>The alt Attribute</h3>
      <p>
        Required `alt` attribute ek alternate text provide karta hai ek image ke
        liye, agar kisi reason se user use nahi dekh sakta (slow connection ke
        karan, `src` attribute mein koi error ho, ya agar user ek screen reader
        ka istemal karta hai).
      </p>
      <p>
        `alt` attribute ki value ko image ko describe karne ke liye istemal
        karna chahiye:
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`<img src="img_chania.jpg" alt="Flowers in Chania">
`}
      </pre>
      <h3>Example</h3>
      <pre className="bgExample">
        {`<img src="wrongname.gif" alt="Flowers in Chania">
`}
      </pre>
      <h2>Image Size - Width and Height</h2>
      <p>
        Aap `style` attribute ka istemal karke ek image ki width aur height ko
        specify kar sakte hain.
      </p>
      <h3>Example</h3>
      <p>
        {`<img src="img_girl.jpg" alt="Girl in a jacket" style="width:500px;height:600px;">
`}
      </p>
      <p>
        as an option, aap `width` aur `height` attributes ka istemal kar sakte
        hain:
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`<img src="img_girl.jpg" alt="Girl in a jacket" width="500" height="600">
`}
      </pre>
      <p>
        `width` aur `height` attributes hamesha image ki width aur height ko
        pixels mein define karte hain.
      </p>
      <p>
        Note: Hamesha image ki width aur height ko specify karein. Agar width
        aur height specify nahi kiye gaye hain, toh web page image load hoti hai
        tab flicker ho sakta hai
      </p>
      <h2>
        `width`, `height`, aur `style` attributes sabhi HTML mein valid hain.
      </h2>
      <p>
        `width`, `height`, aur `style` attributes sabhi HTML mein valid hain.
      </p>
      <p>
        Halaanki, hum suggest karte hain ki aap `style` attribute ka istemal
        karein. Ye styles sheets se image ke size ko badalne se bachata hai:
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
<!DOCTYPE html>
<html>
<head>
<style>
img {
  width: 100%;
}
</style>
</head>
<body>

<img src="html5.gif" alt="HTML5 Icon" width="128" height="128">

<img src="html5.gif" alt="HTML5 Icon" style="width:128px;height:128px;">

</body>
</html> 
`}
      </pre>
      <h2>Images in Another Folder</h2>
      <p>
        Agar aapki images ek subfolder mein hain, toh aapko `src` attribute mein
        folder ka naam add karna hoga:
      </p>
      <pre className="bgExample">
        {`
    <img src="/images/html5.gif" alt="HTML5 Icon" style="width:128px;height:128px;">
    `}
      </pre>
      <h2>Images on Another Server/Website</h2>
      <p>
        Kuch websites doosre server par rakhe gaye images ko point karte hain.
      </p>
      <p>
        Doosre server par rakhe gaye image ko point karne ke liye, aapko `src`
        attribute mein ek absolute (full) URL specify karna hoga:
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`<img src="https://hinglishweb.physitask.com/images/hey.jpg" alt="hinglishweb">
`}
      </pre>
      <h2>Animated Images</h2>
      <p>HTML animated GIFs ko allow karta hai.</p>
      <h3>Example</h3>
      <p>
        {`<img src="programming.gif" alt="Computer Man" style="width:48px;height:48px;">
`}
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`<img src="programming.gif" alt="Computer Man" style="width:48px;height:48px;"> 
`}
      </pre>
      <h2>How to use image as link?</h2>
      <p>Ek image ko as link istemal karne ke liye, {`<img>`} tag ko {`<a>`} tag ke inside rakhein:
</p>
<h3>Example</h3>
<pre className="bgExample">
{`
<a href="default.asp">
<img src="smiley.gif" alt="HTML tutorial" style="width:42px;height:42px;">
</a>
`}
</pre>
<h2>Image Floating</h2>
<p>CSS {`float`} property ka istemal karein taaki image ko text ke right ya left mein float kara sake.
</p>
<h3>Example</h3>
<pre className="bgExample">
    {`
    <p><img src="smiley.gif" alt="Smiley face" style="float:right;width:42px;height:42px;">
    `}
</pre>
<p>The image will float to the right of the text</p>
<pre className="bgExample">
    {`
    <p><img src="smiley.gif" alt="Smiley face" style="float:left;width:42px;height:42px;">
    `}
</pre>
<p>The image will float to the left of the text.
</p>
<h2>Common Image Formats
</h2>
    </div>
  );
}
