import React from "react";

export default function CssHtml() {
  return (
    <div className="bContainer container">
      <h1> CSS - Styles in HTML</h1>
      <p>CSS ka matlab hota hai Cascading Style Sheets.</p>
      <p>
        CSS bahut saara kaam bachata hai. Ye ek saath multiple web pages ka
        layout control kar sakta hai.
      </p>
      <h2>What is CSS (Cascading Style Sheets) ?</h2>
      <p>
        Cascading Style Sheets (CSS) ka istemal ek webpage ka layout format
        karne ke liye hota hai.
      </p>
      <p>
        CSS ke zariye aap text ke color, font, size, elements ke beech ke
        spacing, elements ki positioning aur layout, background images ya
        background colors, alag-alag devices aur screen sizes ke liye alag
        displays, aur bahut kuch control kar sakte hain!
      </p>
      <p className="bgYellow">
        Tip: Cascading ka matlab hai ki jo style parent element par apply hoti
        hai, wahi style saare children elements par bhi apply hogi jo us parent
        ke andar hain. Toh agar aap body text ka color "blue" set karte hain,
        toh saare headings, paragraphs, aur dusre text elements body ke andar
        bhi wahi color mein honge (jab tak aap kuch aur specify nahi karte)!
      </p>
      <h2>Using CSS</h2>
      <p>
        CSS ko HTML documents mein 3 tareekon se add kiya ja sakta hai:
      </p>{" "}
      <h4>
        1. Inline - HTML elements ke andar style attribute ka istemal karke
      </h4>
      <h4>
        2. Internal - {`<head>`} section mein {`<style>`} element ka istemal
        karke
      </h4>
      <h4>
        3. External - {`<link>`} element ka istemal karke ek external CSS file
        se link karke
      </h4>
      <p>
        Sabse normal tareeka CSS ko add karne ka, styles ko external CSS files
        mein rakhna hai. Lekin is tutorial mein hum inline aur internal styles
        ka istemal karenge, kyun ki ye show karne mein aasan hai, aur aap khud
        bhi try kar sakte hain.
      </p>
      <h2>Inline CSS</h2>
      <p>
        Ek inline CSS ka istemal ek single HTML element par ek alag style lagane
        ke liye hota hai.
      </p>
      <p>
        Inline CSS ka istemal ek HTML element ke style attribute se hota hai.
      </p>
      <p>
        Niche diye gaye example mein, {`<h1>`} element ki text color ko blue aur{" "}
        {`<p>`} element ki text color ko red set kiya gaya hai:
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
          <h1 style="color: blue;">I am a heading</h1>
          <p style="color: red;">I am a paragraph.</p>
        `}
      </pre>
      <h3>Output</h3>
      <div className="bgWhite">
        <h1 style={{ color: "blue" }}>I am a heading</h1>
        <p style={{ color: "red" }}>I am a paragraph.</p>
      </div>
      <h2>Internal CSS</h2>
      <p>
        Internal CSS ka istemal ek single HTML page ke liye ek style define
        karne ke liye hota hai.
      </p>
      <p>
        Internal CSS ek HTML page ke {`<head>`} section ke andar, {`<style>`}{" "}
        element ke inside define kiya jata hai.
      </p>
      <p>
        Niche diye gaye example mein, ALL {`<h1>`} elements ki text color ko
        green aur ALL {`<p>`} elements ki text color ko yellow set kiya gaya
        hai. Iske alawa, page ko "black" background color ke saath dikhaya
        jayega:
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
    <!DOCTYPE html>
    <html>
    <head>
      <title>Internal CSS Example</title>
      <style>
        h1 {
          color: green;
        }
        p {
          color: yellow;
        }
        body {
          background-color: black;
        }
      </style>
    </head>
    <body>
    
      <h1>I am a heading</h1>
      <p>I am a paragraph.</p>
    
    </body>
    </html>
    `}
      </pre>
      <h3>Output</h3>
      <div style={{ backgroundColor: "black" }}>
        <h1 style={{ color: "green" }}>I am a heading</h1>
        <p style={{ color: "yellow" }}>I am a paragraph.</p>
      </div>
      <h2>External CSS</h2>
      <p>
        Ek external style sheet ka istemal bahut saari HTML pages ke liye ek
        style define karne ke liye hota hai.
      </p>
      <p>
        External style sheet ka istemal karne ke liye, har HTML page ke{" "}
        {`<head>`} section mein uski link add karein:
      </p>
      <p>
        Yahan, `href` attribute mein external style sheet ka path diya gaya hai
        ({`styles.css`}), jo ki har {`HTML`} page ke liye common {`style `}provide
        karega. Is tareeke se, aap ek hi {`style`} sheet ko multiple pages mein
        reuse kar sakte hain.
      </p>
      <p>
        Certainly, you can create an external {`style `}sheet using any {`text`} editor.
        The file should not contain any {`HTML code`} and must be saved with a .css
        extension. Here's an example of what the "styles.css" file might look
        like:
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
        /* styles.css */

        h1 {
            color: green;
          }
          p {
            color: yellow;
          }
          body {
            background-color: black;
          }
        `}
      </pre>
      <p>
      In this example, the CSS file contains styling rules for `h1`, `p`, and `body` elements. These rules will be applied to the corresponding elements on any HTML page linked to this external style sheet.

      </p>
    </div>
  );
}
