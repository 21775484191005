import React from "react";

export default function FaviconHtml() {
  return (
    <div className="bContainer container">
      <h1>HTML Favicon</h1>
      <h2>How To Add a Favicon in HTML ?</h2>
      <p>
        Aap apne kisi bhi selected image ko apne as favicon istemal kar sakte
        hain.{" "}
      </p>
      <p>
        Tip: Favicon ek chhota image hota hai, isliye yeh ek sadharan simple
        hona chahiye..
      </p>
      <p>
        Ek favicon image ko browser ke tab mein page title ke left taraf show
        hota hai:
      </p>
      <p>
        Apne website mein ek favicon add karne ke liye, ya to apne favicon image
        ko apne web server ke root directory mein save karein, ya fir ek folder
        banayein jo root directory mein 'images' ke naam se ho, aur apne favicon
        image ko is folder mein save karein. Ek simple naam favicon image ka
        'favicon.ico' hota hai.
      </p>
      <p>
        Uske baad, apne "index.html" file mein, {`<title>`} element ke baad, ek{" "}
        {`<link>`} element ko is tarah se add karein:
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
    <!DOCTYPE html>
    <html>
    <head>
      <title>Title Page</title>
      <link rel="icon" type="image/x-icon" href="/images/favicon.ico">
    </head>
    <body>
    
    
    <h1>I am a Heading</h1>
    
    
    </body>
    </html>
    
    `}
      </pre>
      <h2>Favicon File Format Support</h2>
      <p>Following are the supperted file formats:</p>
      <ul>
        <li>ICO</li>
        <li>PNG</li>
        <li>GIF</li>
        <li>JPEG</li>
        <li>SVG</li>
      </ul>
    </div>
  );
}
