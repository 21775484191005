import React from "react";

export default function StyleHtml() {
  return (
    <div className="bContainer container">
      <h1>Styles in HTML </h1>
      <p>
        HTML style attribute ka istemaal ek element mein styles jaise ki colour,
        font, size etc. add karne ke liye hota hai.
      </p>
      <h3>Example</h3>
      <pre>
        <h3 className="clrRed">I am Red</h3>
        <h4 style={{ color: "green" }}>I am Red</h4>
        <h5 style={{ color: "purple" }}>I am Red</h5>
      </pre>
      <h3>The HTML Style Attribute</h3>
      <p>
        HTML element ki style set karne ke liye, style attribute ka istemaal
        kiya ja sakta hai.
      </p>
      <p>HTML style attribute ka syntax is tarah ka hota hai:</p>
      <pre>
        {`
        <tagname style="property:value;">
`}
      </pre>
      <h2>Background Colour</h2>
      <p>
        CSS background-colour property HTML element ke liye background colour ko
        define karta hai.
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`html
        <body style="background-color: powderblue;">
        
        <h1>This is a heading</h1>
        <p>This is a paragraph.</p>
        
        </body>
        `}
      </pre>
      <h3>Example</h3>
      <p>
        CSS colour property HTML element ke liye text colour define karta hai:
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`html
            <h1 style="colour:blue;">Ye ek heading hai</h1>
            <p style="color:red;">Ye ek paragraph hai.</p>
            `}
      </pre>
      <h2>Fonts</h2>
      <p>
        CSS font-family property HTML element ke liye istemal hone wale font ko
        define karta hai.
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
        html
<h1 style="font-family:verdana;">Ye ek heading hai</h1>
<p style="font-family:courier;">Ye ek paragraph hai.</p>
`}
      </pre>
      <h2>Text Size</h2>
      <p>
        CSS font-size property HTML element ke liye text size define karta hai:
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
        html
        <h1 style="font-size:300%;">Ye ek heading hai</h1>
        <p style="font-size:160%;">Ye ek paragraph hai.</p>
        `}
      </pre>
      <h3>Text Alignment</h3>
      <p>
        CSS text-align property HTML element ke liye horizontal text alignment
        define karta hai:
      </p>
      <h3>Example</h3>
      <p>
        {`html
<h1 style="text-align:centre;">Centred Heading</h1>
<p style="text-align:center;">Centred paragraph.</p>
`}
      </p>
      <h2>Chapter Summary</h2>
      <p>
        HTML elements ko style karne ke liye style attribute ka istemal karein:
      </p>
      <p>- Background colour ke liye `background-colour` ka istemal karein
</p>
<p>- Text color ke liye `colour` ka istemal karein
</p>
<p>- Text fonts ke liye `font-family` ka istemal karein
</p>
<p>- Text sizes ke liye `font-size` ka istemal karein
</p>
<p>- Text alignment ke liye `text-align` ka istemal karein
</p>
    </div>
  );
}
