import React, { useState } from "react";

export default function IdHtml() {
  const [text, setText] = useState("Hello India!");

  const changeText = () => {
    setText("Namaste Bharat!");
  };

  return (
    <div className="bContainer container">
      <h1 id="idAttribute">HTML id Attribute</h1>
      <p>
        HTML id attribute ka use ek HTML element ke liye ek specific id dene ke
        liye kiya jata hai.
      </p>
      <p>
        Ek HTML document mein ek se adhik element same id use nahi kar sakte.
      </p>
      <p>
        HTML document mein Har ek id unique honi chahiye taaki yeh sahi dhang se
        ek unique element ko identify kar sake. Is uniqueness ka importance CSS
        ko sahi se styling ke liye aur JavaScript ko sahi element ko select aur
        manipulate karne ke liye hota hai.
      </p>
      <p>
        Yeh ek style sheet mein ek unique style declaration ko point karne ke
        liye istemal hota hai aur iss se JavaScript dwara unique id ke saath
        element ko identify kiya jata hai aur use manipulate kiya jata hai.
      </p>
      <p>
        Id ka <b>syntax</b> ek hash character (#) ke saath id name likhna hai.
        Phir, curly braces {} ke andar CSS properties ko define karna hai.
      </p>
      <p>
        Example mein jo aapne bataya, ek {`<h2> `}element "heyHeader" id naam ko
        point karta hai. Ye {`<h2> `}element head section mein #heyHeader style
        definition ke according styled hoga:
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
        <head>
        <style>
          #heyHeader {
            color: green;
            font-size: 20px;
          }
        </style>
        </head>
      
        <body>
        <h1 id="heyHeader">I am a Header</h1>
        </body>
      
        `}
      </pre>
      <h3>Output</h3>
      <div className="bgWhite" style={{ color: "green", fontSize: "20px" }}>
        <h1 id="heyHeader">I am a Header</h1>
      </div>
      <p>
        Here, the #heyHeader style definition in the head section will be
        applied to the {`<h1>`} element with the id "heyHeader".
      </p>
      <h2>Difference Between Class and ID</h2>
      <p>
        Ek class name ko kai HTML elements dwara use kiya ja sakta hai, jabki ek
        id name ko sirf ek HTML element dwara hi page ke andar istemal kiya ja
        sakta hai.
      </p>
      <h2>HTML Bookmarks with ID and Links</h2>
      <p>
        {" "}
        HTML bookmarks ka istemal isliye kiya jata hai taki readers ko webpage
        ke kuch khaas hisson par jump karne mein help ki ja sake.
      </p>
      <p>
        Bookmarks ka istemal tab faydemand hota hai jab aapki page bahut lambi
        hoti hai.
      </p>
      <p>
        Bookmark ka istemal karne ke liye, aapko pehle use banana hoga, aur phir
        uske liye ek link jodna hoga.
      </p>
      <p>
        Phir, jab link par click kiya jata hai, page bookmark ke place par
        scroll ho jata hai."
      </p>
      <h3>Example</h3>
      <p>First, create a bookmark with the id attribute:</p>
      <pre className="bgExample">
        {`
      <h1 id="idAttribute">HTML id Attribute</h1>
      `}
      </pre>
      <p>
        Then, add a link to the bookmark ("Jump to Lesson 5"), from within the
        same page:
      </p>
      <h3>Output</h3>
      <div className="bgWhite">
        <a href="#idAttribute">HTML id Attribute</a>
      </div>
      <h2>Using The id Attribute in JavaScript</h2>
      <p>
        {" "}
        Id attribute ko JavaScript bhi kisi specific element ke liye kuch task
        perform karne ke liye istemal kar sakte hain.
      </p>
      <p>
        JavaScript getElementById() method ke saath ek specific id ke saath ek
        element ko access kiya ja sakta hai:
      </p>
      <h3>Example</h3>
      <p>id attribute to manipulate text with JavaScript:</p>
      <pre className="bgExample">
        {`
       <!DOCTYPE html>
       <html>
       <body>
       
       
       <h2 id="myHeader">Hello India!</h2>
       <button onclick="displayResult()">Change text</button>
       
       <script>
       function displayResult() {
         document.getElementById("myHeader").innerHTML = "Namaste Bharat!";
       }
       </script>
       
       </body>
       </html>
        `}
      </pre>
      <h3>Output</h3>
      <div className="bgWhite">
        <h2>{text}</h2>
        <button onClick={changeText}>Change text</button>
      </div>
      
    </div>
  );
}
