import React from "react";

export default function ColorsHtml() {
  return (
    <div className="bContainer container">
      <h1>Colors in HTML</h1>
      <p>
        HTML colors predefined color names ke sath ya RGB, HEX, HSL, RGBA, ya
        HSLA values ke sath specify kiye jaate hain.
      </p>
      <h2>Color Names</h2>
      <p>In HTML, a color can be specified by using a color name:</p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
        <h2 style="background-color:Blue;">Namaste INDIA</h2>
        <p style="background-color:Red;">Welcome to Hinglish Web</p>
        `}
      </pre>
      <h3>Output</h3>
      <div className="bgWhite">
        <h2 style={{ backgroundColor: "blue" }}>Namaste INDIA</h2>
        <p style={{ backgroundColor: "red" }}>Welcome to Hinglish Web</p>{" "}
      </div>
      <h2>Border Color</h2>
      <pre className="bgExample">
        {`
        <h2 style="border:2px solid Red;">Namaste INDIA</h2>
        <h2 style="border:2px solid Blue;">Namaste INDIA</h2>
        <h2 style="border:2px solid Green;">Namaste INDIA</h2>
        
        `}
      </pre>
      <h3>Output</h3>
      <div className="bgWhite">
        <h2 style={{ border: "2px solid blue" }}>Namaste INDIA</h2>
        <h2 style={{ border: "2px solid red" }}>Namaste INDIA</h2>
        <h2 style={{ border: "2px solid green" }}>Namaste INDIA</h2>
      </div>
      <h2>RGB and RGBA Colors in HTML</h2>
      <p>
        Ek RGB color value RED, GREEN, aur BLUE light sources ko represent karta
        hai.
      </p>
      <h2>RGB Color Values</h2>
      <p>
        HTML mein, ek color ko as RGB value specify karne ke liye ye formula
        istemal hota hai:
      </p>
      <p>
        <b>rgb(red, green, blue)</b>
      </p>
      <p>
        Yahan, `red`, `green`, aur `blue` 0 se 255 tak ke integers hote hain, jo
        color ka intensity define karte hain.
      </p>
      <p>
        Har parameter (red, green, aur blue) color ki intensity ko define karta
        hai, jiska value 0 se 255 ke beech hota hai.
      </p>
      <p>
        Iska matlab hai ki 256 x 256 x 256 = 16777216 possible colors hote hain!
      </p>
      <p>
        For example, `rgb(255, 0, 0)` ko as red display kiya jata hai, kyun ki
        red uski highest value (255) par set hai, aur doosre dono (green aur
        blue) 0 par hain.
      </p>
      <p>
        Ek aur example, `rgb(0, 255, 0)` ko as green display kiya jata hai, kyun
        ki green uski highest value (255) par set hai, aur doosre dono (red aur
        blue) 0 par hain.
      </p>
      <p>
        Black ko display karne ke liye, sabhi color parameters ko 0 par set
        karein, jaise ki: `rgb(0, 0, 0)`.
      </p>
      <p>
        White ko display karne ke liye, sabhi color parameters ko 255 par set
        karein, jaise ki: `rgb(255, 255, 255)`.
      </p>
      <h2>RGBA Color Values in HTML</h2>
      <p>
        <b>rgba(red, green, blue, alpha)</b>
      </p>
      <p>
        RGBA color values RGB color values ka ek extention hai jisme ek Alpha
        channel hota hai - jo ek color ke liye opacity set karta hai.
      </p>
      <p>
        Alpha parameter 0.0 (completely transparent) and 1.0 (not transparent at
        all) ke beech ek number hota hai:
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
        <span style="background-color: rgba(190, 23, 255, 1); padding: 5px; margin: 5px;">rgba(190, 23, 255, 1)</span>

        <span style="background-color: rgba(190, 23, 255, 0.5); padding: 5px; margin: 5px;">rgba(190, 23, 255, 0.5)</span>
        
        <span style="background-color: rgba(190, 23, 255, 0.2); padding: 5px; margin: 5px;">rgba(190, 23, 255, 0.2)</span>
        
        `}
      </pre>
      <h3>Output</h3>
      <div className="bgWhite">
        <span
          style={{
            backgroundColor: "rgba(190, 23, 255, 1)",
            padding: "5px",
            margin: "5px",
          }}
        >
          rgba(190, 23, 255, 1)
        </span>

        <span
          style={{
            backgroundColor: "rgba(190, 23, 255, 0.5)",
            padding: "5px",
            margin: "5px",
          }}
        >
          rgba(190, 23, 255, 0.5)
        </span>
        <span
          style={{
            backgroundColor: "rgba(190, 23, 255, 0.2)",
            padding: "5px",
            margin: "5px",
          }}
        >
          rgba(190, 23, 255, 0.2)
        </span>
      </div>
      <h2>HEX Colors in HTML</h2>
      <p>Ek hexadecimal colour is tarah se specify hota hai:</p>
      <p>
        <b>#RRGGBB</b>
      </p>
      <p>
        Yahan, RR (red), GG (green), aur BB (blue) hexadecimal integers color ke
        components ko define karte hain.
      </p>
      <p>HEX Color Values</p>
      <p>
        HTML mein, ek rang ko as hexadecimal value specify kiya ja sakta hai,
        jiska format hai: <b>#rrggbb</b>
      </p>
      <p>
        Yahan rr (red), gg (green), aur bb (blue) hexadecimal values hote hain
        jo 00 se lekar ff ke beech mein hoti hain (jo decimal ke 0 se lekar 255
        ke equal hota hai).
      </p>
      <p>
        Ek aur udaharan, #00ff00 green show hota hai, kyun ki green uski sabse
        adhik value (ff) par set hai, aur doosre do (red aur blue) 00 par set
        hain.
      </p>
      <p>
        Black color show karne ke liye, saare color parameters ko 00 par set
        karein, is tarah se: #000000.
      </p>
      <p>Aap in HEX values ko combine karke naye rang bana sakte hain!</p>
      <p>
        <b>#ff6347</b>
      </p>
      <h2>HSL and HSLA Colors in HTML</h2>
      <p>
        HSL ka matlab hota hai <b>hue</b>, <b>saturation</b>, aur{" "}
        <b>lightness</b>.
      </p>
      <p>
        HSLA color values HSL ka extention hai jisme ek Alpha channel (opacity)
        bhi hota hai.
      </p>
      <h2>HSL Color Values</h2>
      <p>
        In HTML, ek rang ko specify karne ke liye hue, saturation, aur lightness
        (HSL) ka use hota hai
      </p>
      <p>
        <b>hsl(hue, saturation, lightness)</b>
      </p>
      <h3>Hue</h3>
      <p>
        Hue ek degree hai color wheel par 0 se lekar 360 tak. 0 red hai, 120
        green hai, aur 240 blue hai.
      </p>
      <p>
        Saturation ek percentage value hai. 0% ek shade of gray ko show karta
        hai, aur 100% poora rang hai.
      </p>
      <p>
        Lightness bhi ek percentage value hai. 0% ka matlab hai kaala, aur 100%
        safed hai.
      </p>
      <h3>Saturation</h3>
      <p>Saturation color ki intensity show karta hai.</p>
      <p>100% ek pure color hai, koi bhi shade of gray nahi hai.</p>
      <p>
        50% 50% gray hoga, lekin aap tab bhi rang dekh sakte hain. <br />
        0% pure gray hota hai; aap rang ab dekh nahi sakte.
      </p>
      <h3>Lightness</h3>
      <p>
        Ek rang ki lightness ko us rang mein kitni light deni hai iske liye use kiya jaata hai, jahan 0% ka matlab hai 0 light(kaala), 50% ka matlab hai 50% light (na to kaala na hi safed), aur
        100% ka matlab hai 100% light(safed).
      </p>
    </div>
  );
}
