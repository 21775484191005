import React from "react";

export default function ParagraphHtml() {
  return (
    <div className="bContainer container">
      <h1>HTML Paragraph</h1>
      <p>
        Ek paragraph hamesha ek naye line par shuru hota hai aur aam taur par ek
        text block hota hai.
      </p>
      <p>HTML {`<p>`} tatva ek paragraph ko define karta hai.</p>
      <p>
        Ek paragraph hamesha ek naye line par shuru hota hai, aur browsers apne
        aap kuch white space (margin) paragraph ke pehle aur baad mein add kar
        dete hain.
      </p>
      <h3>Example</h3>

      <pre className="bgExample">
        {`
        <p>Hey! i am a paragraph.</p>
        <p>Hey! i am a paragraph too.</p>
        `}
      </pre>

      <h3>Output</h3>
      <div className="bgwhite">
        <p>Hey! i am a paragraph.</p>
        <p>Hey! i am a paragraph too.</p>
      </div>
      <h2>Display in HTML</h2>

      <p>You cannot be sure how HTML will be displayed.</p>
      <p>
        Large or small screens, and resized windows will create different
        results.
      </p>
      <p>
        With HTML, you cannot change the display by adding extra spaces or extra
        lines in your HTML code.
      </p>
      <p>
        The browser will automatically remove any extra spaces and lines when
        the page is displayed:
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
    <p>
        Iss paragraph
        mein bahut saari lines
        source code mein hain,
        par browser
        use ignore karta hai.
    </p>
    `}
      </pre>
      <h3>Output</h3>
      <div className="bgWhite">
        <p>
          Iss paragraph mein bahut saari lines source code mein hain, par
          browser use ignore karta hai.
        </p>
      </div>
      <h2>Horizontal Rules in HTML</h2>
      <p>
        {" "}
        {`<hr>`} tag ek HTML page mein ek break ko define karta hai, aur aksar
        ek horizontal rule ke roop mein dikhaya jata hai.
      </p>
      <p>
        {`<hr>`} element ka use HTML page mein content ko alag karna (ya ek
        parivartan ko define karne) ke liye kiya jata hai:
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
        <h1>Ye h1 heading  hai</h1>
        <p>Ye kuch text hai.</p>
        <hr/>
        <h2>Ye h2 heading hai</h2>
        <p>Ye kuch aur text hai.</p>
        <hr/>
        <hr/> tag ek khali tag hai, iska matlab hai ki iska koi end tag nahi hota.


        `}
      </pre>
      <h3>Output</h3>
      <div className="bgWhite">
        <h1>Ye h1 heading hai</h1>
        <p>Ye kuch text hai.</p>
        <hr />
        <h2>Ye h2 heading hai</h2>
        <p>Ye kuch aur text hai.</p>
        <hr />
        <hr /> tag ek khali tag hai, iska matlab hai ki iska koi end tag nahi
        hota.
      </div>
      <h2>HTML Line Breaks</h2>
      <p>HTML {`<br>`} element ek line break define karta hai.</p>
      <p>
        {" "}
        {`<br>`} ka istemal karein agar aap ek line break (naya line) chahte
        hain bina kisi naye paragraph ke shuru kiye:
      </p>
      <h3>Example</h3>
      <pre className="bgWhite">
        {`
        <p>
          Ye ek <br/> paragraph hai <br/> jo line breaks ke saath hai.
        </p>
        `}
      </pre>
      <h3>Output</h3>
      <div className="bgWhite">
        <p>
          Ye ek <br /> paragraph hai <br /> jo line breaks ke saath hai.
        </p>
      </div>
      <h2>How to write Poem in HTML paragraph?</h2>

      <h3>Example</h3>
      <pre className="bgExample">
        {` 
     <p>
       जिसने सूरज चाँद बनाया
       जिसने तारों को चमकाया- 
       जिसने फूलों को महकाया-
       जिसने चिड़ियों को चहकाया-
       जिसने सारा जगत् बनाया, 
       हम उस ईश्वर के गुण गाएं
       हम उसे प्रेम से शीश झुकायें...
    </p>
`}
      </pre>
      <h3>Output</h3>
      <p>Ye kavita ek hi line mein show ki jayegi:</p>
      <div className="bgWhite">
        <p>
          जिसने सूरज चाँद बनाया जिसने तारों को चमकाया- जिसने फूलों को महकाया-
          जिसने चिड़ियों को चहकाया- जिसने सारा जगत् बनाया, हम उस ईश्वर के गुण
          गाएं हम उसे प्रेम से शीश झुकायें...
        </p>
      </div>
      <h2>Solution</h2>
      <p>
        HTML {`<pre>`} element preformatted text ko define karta hai. <br />
        {`<pre>`} element ke andar ka text ek fixed-width font mein dikhaya jata
        hai, aur yeh spaces aur line breaks ko dono preserve karta hai:
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
          {`
        <pre>
        जिसने सूरज चाँद बनाया
        जिसने तारों को चमकाया- 
        जिसने फूलों को महकाया-
        जिसने चिड़ियों को चहकाया-
        जिसने सारा जगत् बनाया, 
        हम उस ईश्वर के गुण गाएं
        हम उसे प्रेम से शीश झुकायें...
        </pre>
          `}
      </pre>
      <h3>Output</h3>
      <div className="bgWhite">
    <pre>
       {`
        जिसने सूरज चाँद बनाया
        जिसने तारों को चमकाया- 
        जिसने फूलों को महकाया-
        जिसने चिड़ियों को चहकाया-
        जिसने सारा जगत् बनाया, 
        हम उस ईश्वर के गुण गाएं
        हम उसे प्रेम से शीश झुकायें...
        `}
    </pre>
      </div>
    </div>
  );
}
