import React from "react";

export default function HtmlHeading() {
  return (
    <div className="bContainer container">
      <h1>Heading in HTML</h1>
      <p>
        HTML headings ko titles ya subtitles kaha jata hai jo aap webpage par
        show karna chahte hain. <br />
        HTML headings ko {`<h1>`} se lekar {`<h6>`} tak ke tags ke saath define
        kiya jata hai.
        <br />
        {`<h1>`} sabse important and sabse bade font size ki heading ko define
        karta hai. {`<h6>`} sabse small font size ki heading ko define karta
        hai. <br />
        iss page ka title <span className="clrRed">"Heading in HTML"</span>{" "}
        {`<h1>`} heading mein likha gaya hai.
      </p>
      <h3>Example:</h3>
      <pre className="bgExample">
        {`  
    <h2>h2 Heading in HTML</h2>
    <h3>h3 Heading in HTML</h3>
    <h4>h4 Heading in HTML</h4>
    <h4>h4 Heading in HTML</h4>
    <h5>h5 Heading in HTML</h5>
    <h6>h6 Heading in HTML</h6>
    `}
      </pre>
      <b>
        <b>Output :</b>
      </b>
      <div className="bgWhite">
        <h2>h2 Heading in HTML</h2>
        <h3>h3 Heading in HTML</h3>
        <h4>h4 Heading in HTML</h4>
        <h4>h4 Heading in HTML</h4>
        <h5>h5 Heading in HTML</h5>
        <h6>h6 Heading in HTML</h6>
      </div>
      <h2>Are Headings Important ?</h2>
      <p>
      Search engines headings ka istemal karte hain aapki web pages ki structure aur content ko index karne ke liye and proper use of headings content ki readability improve karta hai.
<br />

{`<h1>`} headings ko main headings ke liye istemal karna chahiye , uske baad {`<h2>`} headings ko and phir {`<h3>`}, aur aise hi aage isi order main importence ke hisab se headings use karne chahiye.
<br />
Har HTML heading ka ek default size hota hai. Haan lekin, aap kisi bhi heading ke liye size specify kar sakte hain style attribute ka istemal karke, CSS font-size property ka istemal karke:
<h3>Example:</h3>
<pre>
    {`
    <h3 style="font-size:75px;">75px HTML Heading</h3>
    `}
</pre>
<p><b>Output:</b></p>
<div className="bgWhite">
<h3 style={{fontSize:'75px'}}>75px HTML Heading</h3>

</div>

      </p>
    </div>
  );
}
