import React from "react";
import { Route, Routes } from "react-router-dom";
import Html from "../pages/html/Html";
import HtmlHome from "../pages/html/HtmlHome";
import Elements from "../pages/html/Elements";
import Attributes from "../pages/html/Attributes";
import HtmlHeading from "../pages/html/HtmlHeading";
import ParagraphHtml from "../pages/html/ParagraphHtml";
import StyleHtml from "../pages/html/StyleHtml";
import FormattingHtml from "../pages/html/FormattingHtml";
import CommentsHtml from "../pages/html/CommentsHtml";
import ColorsHtml from "../pages/html/ColorsHtml";
import CssHtml from "../pages/html/CssHtml";
import HtmlLinks from "../pages/html/HtmlLinks";
import ImagesHtml from "../pages/html/ImagesHtml";
import FaviconHtml from "../pages/html/FaviconHtml";
import TableHtml from "../pages/html/TableHtml";
import ListsHtml from "../pages/html/ListsHtml";
import BlockAndInlineElements from "../pages/html/BlockAndInlineElements";
import DivHtml from "../pages/html/DivHtml";
import ClassHtml from "../pages/html/ClassHtml";
import IdHtml from "../pages/html/IdHtml";
import IFrameHtml from "../pages/html/IFrameHtml";
import JavascriptHtml from "../pages/html/JavascriptHtml";
import FilePathHtml from "../pages/html/FilePathHtml";

export default function HtmlRoutes() {
  return (
    <Routes>
      <Route path="/html-hindi" element={<Html />}>
        {/* <Route path="html-introduction-hindi" element={<HtmlHome />} /> */}
        <Route index element={<HtmlHome />} />
        <Route path="html-element-hindi" element={<Elements />} />
        <Route path="html-attributes-hindi" element={<Attributes />} />
        <Route path="html-heading-hindi" element={<HtmlHeading />} />
        <Route path="html-style-hindi" element={<StyleHtml />} />
        <Route path="html-paragraph-hindi" element={<ParagraphHtml />} />
        <Route path="html-text-formatting-hindi" element={<FormattingHtml />} />
        <Route path="html-comments-hindi" element={<CommentsHtml />} />
        <Route path="html-colors-hindi" element={<ColorsHtml />} />
        <Route path="html-css-hindi" element={<CssHtml />} />
        <Route path="html-image-hindi" element={<ImagesHtml />} />
        <Route path="html-links-hindi" element={<HtmlLinks />} />
        <Route path="html-favicon-hindi" element={<FaviconHtml />} />
        <Route path="html-table-hindi" element={<TableHtml />} />
        <Route path="html-lists-hindi" element={<ListsHtml />} />
        <Route path="html-block-level-and-inline-element-hindi" element={<BlockAndInlineElements />} />
        <Route path="html-div-element-hindi" element={<DivHtml />} />
        <Route path="html-class-attribute-hindi" element={<ClassHtml />} />
        <Route path="html-id-attribute-hindi" element={<IdHtml />} />
        <Route path="html-iframe-hindi" element={<IFrameHtml />} />
        <Route path="html-javascript-hindi" element={<JavascriptHtml />} />
        <Route path="html-file-path-hindi" element={<FilePathHtml />} />
      </Route>
    </Routes>
  );
}
