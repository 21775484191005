import React from 'react'
import Sidebar from '../../components/Sidebar'
import { Outlet } from 'react-router-dom'

export default function Html() {

    const topics  = [
        // { title: 'HTML Introduction', path: "" },
        // { title: 'HTML Introduction', path: "html-introduction-hindi" },
        { title: 'HTML Elements', path: "html-element-hindi" },
        { title: 'HTML Attributes', path: "html-attributes-hindi" },
        { title: 'HTML Heading', path: "html-heading-hindi" },
        { title: 'HTML Style', path: "html-style-hindi" },
        { title: 'HTML Paragraph', path: "html-paragraph-hindi" },
        { title: 'HTML Text Formatting', path: "html-text-formatting-hindi" },
        { title: 'HTML Comments', path: "html-comments-hindi" },
        { title: 'HTML Colors', path: "html-colors-hindi" },
        { title: 'HTML CSS', path: "html-css-hindi" },
        { title: 'HTML Image', path: "html-image-hindi" },
        { title: 'HTML Links', path: "html-links-hindi" },
        { title: 'HTML Favicon', path: "html-favicon-hindi" },
        { title: 'HTML Table', path: "html-table-hindi" },
        { title: 'HTML Lists', path: "html-lists-hindi" },
        { title: 'HTML Block And Inline Elements', path: "html-block-level-and-inline-element-hindi" },
        { title: 'HTML Div Element', path: "html-div-element-hindi" },
        { title: 'HTML Class Attribute', path: "html-class-attribute-hindi" },
        { title: 'HTML ID Attribute', path: "html-id-attribute-hindi" },
        { title: 'HTML I Frame', path: "html-iframe-hindi" },
        { title: 'HTML JavaScript', path: "html-javascript-hindi" },
        { title: 'HTML File Path', path: "html-file-path-hindi" },
    ]
  return (
    <div>
        <Sidebar topics={topics}/>
        <Outlet/>
        
    </div>
  )
}
