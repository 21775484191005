import React from "react";
import style from "../style/html.css";

export default function ClassHtml() {
  return (
    <div className="bContainer container">
      <h1>HTML class Attribute</h1>
      <p>
        HTML class attribute ka istemal ek HTML element ke liye class specify
        karne ke liye hota hai.
      </p>
      <p>Ek se zyada HTML elements ek hi class ko share kar sakte hain.</p>
      <h2>Using The class Attribute</h2>
      <p>
        Class attribute ko often ek style sheet mein ek class name ko point
        karne ke liye istemal kiya jata hai. Isse JavaScript ko bhi specific
        class name ke elements ko access karne aur unhe manipulate karne mein
        madad milti hai.
      </p>
      <p>
        Niche diye gaye example mein humare paas teen {`<div>`} elements hain
        jinme class attribute ka value "song" hai. In teeno {`<div>`} elements
        ko .song style definition ke mutabiq barabar style kiya jayega head
        section mein:
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
        <!DOCTYPE html>
        <html>
        <head>
        <style>
        .song {
            background-color: rgba(113, 224, 118, 0.879);
            color: white;
            border: 1px solid rgb(8, 64, 234);
            margin: 15px;
            padding: 15px;
          }
        </style>
        </head>
        <body>
        
        
        <div class="song">
        <h2>Movie - Animal</h2>
        <p>Song: Hua Main</p>
        <p>Lyrics: Manoj Muntashir </p>
        <p>Music: Jam8</p>
        </div>

        <div class="song">
        <h2>Movie - RRR</h2>
        <p>Song: Ambar Se Toda</p>
        <p>Singer: Raag Patel</p>
        <p>Lyrics: Varun Grover </p>
        </div>
        
        </body>
        </html>
        `}
      </pre>
      <h3>Output</h3>
      <div className="bgWhite">
        <div className="song">
          <h2>Movie - Animal</h2>
          <p>Song: Hua Main</p>
          <p>Lyrics: Manoj Muntashir </p>
          <p>Music: Jam8</p>
        </div>

        <div className="song">
          <h2>Movie - RRR</h2>
          <p>Song: Ambar Se Toda</p>
          <p>Singer: Raag Patel</p>
          <p>Lyrics: Varun Grover </p>
        </div>
      </div>
      <p className="bgYellow">
        {" "}
        Note: Class attribute ko kisi bhi HTML element par istemal kiya ja sakta
        hai
      </p>
      <p className="bgYellow"> Note: The class name is case sensitive!</p>
      <h2>The Syntax For Class</h2>
      <p>
        Ek class banane ke liye, ek period (.) character likhein, jise ek class
        name follow kare. Fir, CSS properties ko curly braces {} ke andar define
        karein.
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
        .song {
            background-color: rgba(113, 224, 118, 0.879);
            color: white;
            border: 1px solid rgb(8, 64, 234);
            margin: 15px;
            padding: 15px;
          }
        `}
      </pre>
      <p>
        Yahan, .song class ko define kiya gaya hai jisme color aur font-size CSS
        properties di gayi hain.
      </p>
      <h3>Multiple Classes</h3>
      <p>HTML elements ek se zyada classes mein belong ho sakte hain.</p>
      <p>
        Multiple classes define karne ke liye, class names ko space ke saath
        separate karein, jaise ki {`<div class="song main">`}. Element ko sabhi
        specified classes ke according style di jayegi.
      </p>
    </div>
  );
}
