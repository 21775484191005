import React from "react";

export default function IFrameHtml() {
  return (
    <div className="bContainer container">
      <h1> Iframes in HTML</h1>
      <p>
        HTML iframe ka istemal ek web page ko doosre web page ke inside show
        karne ke liye hota hai.
      </p>
      <h2>HTML Iframe Syntax</h2>
      <p>HTML {`<iframe>`} tag ek inline frame ko specify karta hai.</p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
        <iframe src="hinglishweb.physitask.com" title="Hinglish Web"></iframe>
        `}
      </pre>
      <h3>Output</h3>
      <div className="bgWhite">
        <iframe src="hinglishweb.physitask.com" title="Hinglish Web"></iframe>
      </div>
      <p>
        {" "}
        Hamesha ek {`<iframe>`} ke liye title attribute shaamil karna ek achha
        practice hai. Ye screen readers dwara iframe ke content ko read karne ke
        liye istemal kiya jata hai.
      </p>
      <h2>Iframe - Set Height and Width</h2>
      <p>
        Use the height and width attributes to specify the size of the iframe.
      </p>
      <p>The height and width are specified in pixels by default:</p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
        <iframe src="hinglishweb.physitask.com" height="200" width="100%" title="Iframe Example"></iframe>
        `}
      </pre>
      <h3>Output</h3>
      <div className="bgWhite">
        <iframe
          src="hinglishweb.physitask.com"
          height="400"
          width="100%"
          title="Iframe Example"
        ></iframe>
      </div>
      <p>
        Ya phir aap style attribute ko jod sakte hain aur CSS height aur width
        properties ka istemal kar sakte hain:
      </p>
      <h2>Iframe - Remove the Border</h2>
      <p>By default, ek iframe ke aas paas ek border hota hai.</p>
      <p>
        Border ko hatane ke liye, style attribute ko add karein aur CSS border
        property ka istemal karein:
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
        <iframe src="demo_iframe.htm" style="border:none;" title="Iframe Example"></iframe>
        `}
      </pre>

    </div>
  );
}
