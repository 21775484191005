import React from "react";

export default function FormattingHtml() {
  return (
    <div className="bContainer container">
      <h1>HTMl Text Formatting</h1>
      <p>
        Formatting elements ko special types ke text ko display karne ke liye
        design kiya gaya tha.
      </p>
      <p>
        {`<b>`} - Bold text <br />
        {`<strong>`} - Important text <br />
        {`<i>`} - Italic text <br />
        {`<em>`} - Emphasised text <br />
        {`<mark>`} - Marked text <br />
        {`<small>`} - Smaller text <br />
        {`<del>`} - Deleted text <br />
        {`<ins>`} - Inserted text <br />
        {`<sub>`} - Subscript text <br />
        {`<sup>`} - Superscript text <br />
      </p>
      <h2>
        HTML {`<b>`} and {`<strong>`} Elements
      </h2>
      <p>
        HTML {`<b> `}element bold text ko define karta hai, kisi extra mahatva
        ke bina.
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`<b>Yeh text bold hai</b>
`}
      </pre>
      <p>
        HTML {`<strong>`} element text ko strong mahatva ke sath define karta
        hai. Aam taur par iske andar ka content bold mein dikhaya jata hai.
      </p>
      <h3>Example</h3>
      <pre className="bgExample">
        {`<strong>`}Yeh text mahatva purna hai!{`</strong>`}
      </pre>
      <h2>
        HTML {`<i>`} and {`<em>`} Elements
      </h2>
      <p>HTML {`<i>`} element ek text ke ek alternate awaz ya mood ko define karta hai. Aam taur par iske andar ka content italic mein dikhaya jata hai.
</p>
<p>Tip: {`<i>`} tag aksar ek takneeki term, kisi doosre bhasha ka vakya, ek vichar, ek jahaz ka naam, aadi ko darshane ke liye istemal hota hai.
</p>
<h3>
    Example
</h3>
<pre className="bgExample"> {`<i>`} Yeh text italic hai {`</i>`}
</pre>
<p>HTML {`<em>`} element ne text ko emphasise kiya hai. Aam taur par iske andar ka content italic mein dikhaya jata hai.
</p>
<p>Tip: Ek screen reader {`<em> `} ke shabd ko emphasise ke sath sunayega, verbal stress ka istemal karte hue.
</p>
    </div>
  );
}
