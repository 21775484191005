import React from "react";

export default function TableHtml() {
  return (
    <div className="bContainer container">
      <h1>Tables in HTML</h1>
      <p>
        HTML tables web developers ko data ko rows aur columns mein organize
        karne mein help karta hai.
      </p>
      <h2>Lets see, How to make a HTML Table ?</h2>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
    <table>
    <tr>
      <th>Name</th>
      <th>Age</th> 
      <th>Gender</th>
      <th>Profession</th>
    </tr>
    <tr>
      <td>Mahendra Singh Dhoni</td>
      <td>42</td> 
      <td>Male</td>
      <td>Cricketer</td>
    </tr>
    <tr>
      <td>Shahrukh Khan</td>
      <td>58</td> 
      <td>Male</td>
      <td>Actor</td>
    </tr>
    <tr>
      <td>Ranbir Kapoor</td>
      <td>41</td> 
      <td>Male</td>
      <td>Actor</td>
    </tr>
  </table>
    `}
      </pre>
      <div className="bgWhite">
        <table>
          <tr>
            <th>Name</th>
            <th>Age</th>
            <th>Gender</th>
            <th>Profession</th>
          </tr>
          <tr>
            <td>Mahendra Singh Dhoni</td>
            <td>42</td>
            <td>Male</td>
            <td>Cricketer</td>
          </tr>
          <tr>
            <td>Shahrukh Khan</td>
            <td>58</td>
            <td>Male</td>
            <td>Actor</td>
          </tr>
          <tr>
            <td>Ranbir Kapoor</td>
            <td>41</td>
            <td>Male</td>
            <td>Actor</td>
          </tr>
        </table>
      </div>
      <h2>Table Cells</h2>
      <p>
        Har table cell ko {`<td>`} aur {`</td>`} tag se define kiya jata hai.
      </p>
      <p>`td` ka matlab hota hai table data.</p>
      <p>
        Jo bhi {`<td>`} aur {`</td>`} ke beech hota hai, woh table cell ka
        content hota hai.
      </p>
      <h2>Table Rows</h2>
      <p>
        Har table row {`<tr>`} se shuru hoti hai aur {`</tr>`} tag ke saath
        khatam hoti hai.
      </p>
      <p>`tr` ka matlab hota hai table row.</p>
      <p>
        Aap ek table mein jitni bhi rows chahein rakh sakte hain; bas dhyan
        rakhein ki har row mein cells ka samaan ho.
      </p>
      <p className="bgYellow">
        Note: Kabhi-kabhi ek row mein dusre se kam ya jyada cells ho sakti hain.
        Aap uske baare mein aane wale chapters mein sikhenge.
      </p>
      <h2>Table Headers</h2>
      <p>
        Kabhi-kabhi aap chahte hain ki aapke cells table header cells ho. Usi
        case mein, {`<td>`} tag ke bajaye {`<th>`} tag ka istemal karein:
      </p>
      <p>`th` ka matlab hota hai table header.</p>
      <h2>How To Add a Border to HTML Table?</h2>
      <h3>Example</h3>
      <pre className="bgExample">
        {`
        table, th, td {
            border: 2px solid green;
          }
          `}
      </pre>
  <h2>Style Table Borders
</h2>
<p>hum table ko CSS Style ka use karke style kar sakte hain.</p>


    </div>
  );
}
