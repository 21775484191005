import React from 'react'

export default function Services() {
  return (
    <div className='bContainer container'>
        <h1> All Our Free Tutorials</h1>
        <p>
        <a href="/">Hinglish Web - Learn Programming in Hindi</a>
      </p>
      <p>
        <a href="/react-hindi">React JS Full Course in Hindi for Free</a>
      </p>
      <p>
        <a href="/javascript-hindi">JavaScript Full Course in Hindi for Free</a>
      </p>
      <p>
        <a href="/python-hindi">Python Full Course in Hindi for free</a>
      </p>
      <p>
        <a href="/cpp-hindi">C++ Full Course in Hindi for free</a>
      </p>
      <p>
        <a href="/c-hindi">C Language Full Course in Hindi for free</a>
      </p>
      <p>
        <a href="/react-hindi">React JS Full Course in Hindi for Free</a>
      </p>
      <p>
        <a href="/javascript-hindi">JavaScript Full Course in Hindi for Free</a>
      </p>
      <p>
        <a href="/python-hindi">Python Full Course in Hindi for free</a>
      </p>
      <p>
        <a href="/cpp-hindi">C++ Full Course in Hindi for free</a>
      </p>
      <p>
        <a href="/c-hindi">C Language Full Course in Hindi for free</a>
      </p>
    </div>
  )
}
